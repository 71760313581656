<template>
  <v-container>
    <v-card class="px-5 py-3">
      <v-data-table
        :headers="headers"
        :items="items"
        :single-select="true"
        :items-per-page="5"
        loading-text="Идёт загрузка данных..."
        item-key="id"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalItems"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-row align="center">
            <v-btn icon @click="editDiscount(item.id)">
              <v-icon small>
                mdi-pencil
              </v-icon>
            </v-btn>
            <v-btn icon @click="deleteDiscount(item.id)">
              <v-icon small color="red darken-2">
                mdi-delete
              </v-icon>
            </v-btn>
          </v-row>
        </template>
      </v-data-table>
      <v-btn color="primary" class="ma-3" @click="addDiscount">
        Добавить купоны и скидки
      </v-btn>
    </v-card>
  </v-container>
</template>

<script>
import { ServiceApiUrls } from "@/services/accommodationRequests/service.api.js";
import toast from "../../../plugins/toast";

export default {
  name: "Discount",
  data: () => ({
    firstWatcherTrigger: false,
    totalItems: 0,
    options: {},
    loading: true,
    headers: [
      { text: "Название", value: "name", sortable: true, class: "table-title" },
      {
        text: "Вид скидки",
        value: "discount_kind",
        sortable: true,
        class: "table-title"
      },
      {
        text: "Платеж",
        value: "quantity",
        sortable: true,
        class: "table-title"
      },
      {
        text: "Действия",
        value: "actions",
        sortable: true,
        class: "table-title"
      }
    ],
    items: []
  }),
  watch: {
    options: {
      handler() {
        if (!this.firstWatcherTrigger) {
          this.firstWatcherTrigger = true;
          return;
        }
        this.loadRow();
      },
      deep: true
    }
  },
  created() {
    this.loadRow();
  },
  methods: {
    editDiscount(id) {
      this.$router.push("/discount/" + id);
    },
    async deleteDiscount(id) {
      await ServiceApiUrls.deleteDiscount(id);
      this.items = [];
      toast.success("Успешно удалено!");
      this.loadRow();
    },
    addDiscount(id) {
      this.$router.push("/discount");
    },
    async loadRow() {
      let params = {
        "page[number]": this.options.page || 1,
        "page[size]": this.options.itemsPerPage || 10
      };
      let res = await ServiceApiUrls.getDiscounts(params);
      this.totalItems = res.meta.pagination.count;
      this.initialize(res.results);
    },
    initialize(dataSet) {
      this.items = [];
      dataSet.map(el => {
        let discount_kind = "";
        if (el.discount_kind === "fixed") {
          discount_kind = "Фиксированная";
        } else {
          discount_kind = "В процентах";
        }
        const obj = {
          company: el.company,
          deleted: el.deleted,
          discount_kind,
          discount_type: "once",
          end_date: el.end_date,
          id: el.id,
          name: el.name,
          promocode: el.promocode,
          quantity: el.quantity,
          start_date: el.start_date
        };
        this.items.push(obj);
      });
      this.loading = false;
    }
  }
};
</script>

<style scoped>
.title {
  font-size: 18px !important;
  font-weight: 500 !important;
  color: orange !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
</style>
